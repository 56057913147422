/* @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wdth,wght@12..96,75..100,200..800&display=swap');


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Bricolage Grotesque", sans-serif;
}

body {
    background-color: #211b27 !important;
    overflow-x: hidden;
}

.p_15 {
    font-size: 15px;
}

.p_14 {
    font-size: 14px;
}

.p_13 {
    font-size: 13px;
}

.p_16 {
    font-size: 16px !important;
}

.p_17 {
    font-size: 17px;
}

.p_19 {
    font-size: 19px !important;

}

.p_18 {
    font-size: 18px !important;
}

.p_20 {
    font-size: 20px !important;
}

.p_22 {
    font-size: 22px !important;
}

.p_24 {
    font-size: 24px !important;
}

.p_26 {
    font-size: 26px !important;
}

.p_28 {
    font-size: 28px !important;
}

.p_32 {
    font-size: 32px !important;
}

.p_36 {
    font-size: 36px !important;
}

.p_55 {
    font-size: 55px;
}

.p_color {
    color: rgb(170, 169, 169);
}

.fw-200 {
    font-weight: 200;
    color: rgb(206, 205, 205);
}
.fw-300{
    font-weight: 200;
}
.fw-400{
    font-weight: 300;
}

.bg_color {
    background-color: #211b27;
}

.poppins-regular {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.section_1 {
    background-image: url('../img/banner_img.png');
    width: 100%;
    height: 130vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
    position: relative;
}

.banner_Shape {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;

}

.banner_content {
    margin-top: 100px;
}

.navbar-toggler {
    border: 1px solid white !important;
    padding: 5px !important;
}

nav {
    transition: all 0.5s ease-in-out;
}


.nav_btn {
    border: 1px solid white !important;
    border-radius: 20px !important;
    transition: all 0.5s ease-in-out;
}

.nav-bgColor {
    background-color: rgba(23, 19, 29, 0.945) !important;
    transition: all 0.5s ease-in-out;
}

.location_btn{
    color: rgb(224, 224, 224) !important;
    font-weight: 200 !important;
}
.nav_btn:hover,
.location_btn:hover {
    background-image: linear-gradient(to right, #FF1506, #FF7E02 20%, #FCEC39 60%, #3AA779);
    border: 1px solid #FCEC39 !important;
    color: black !important;
    /* font-weight: bold; */
    font-weight: 200 !important;
    transition: all 0.5s ease-in-out;
}

.menuColor {
    background-color: rgb(0, 0, 0, 0.3);
}

.container_space {
    margin-top: 90px !important;
}

#time_container1 .time {
    font-size: 18px !important;
}

/* #time_container1 {
    margin-left: 10%;
} */

.time-navbar {
    display: none;
}

.section_9 {
    position: sticky !important;
    top: 120px;
    z-index: -1;
}

.section_7 {
    position: sticky !important;
    top: -80px;
    /* margin-bottom: 350px !important; */
    z-index: -1;
}

.section_5 {
    background-image: url('../img/Immersive_bg.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

}


.section_5 .content p {
    line-height: 26px;
}

.overview_content h1 {
    font-weight: bold;
    padding-right: 30px;
    font-size: 50px;
    background-image: linear-gradient(#FF7E02, #FCEC39);
    background-clip: text;
    color: transparent;
}

.section_3 h2 {
    font-size: 20px;
    font-weight: bold;
    color: transparent;
}

.venue_bg {
    background-image: url('../img/Venue_bg_img.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 30px;
    overflow: hidden;
}

.about_img {
    border-radius: 20px !important;
}

#about {
    padding-top: 100px !important;
}

.location_btn {
    border: 1px solid white !important;
    color: white !important;
    border-radius: 20px !important;
}

.section_7 .about_content {
    font-size: 22px !important;
    line-height: 38px;
    letter-spacing: 2px;
}

.social_media .item {
    padding-right: 15px;
    font-size: 35px;
}

.text_gradiant,
.about_sub_title {
    background-image: linear-gradient(to right, #FF1506, #FF7E02, #FCEC39, #3AA779, #0CEEFF);
    background-clip: text;
    color: transparent;
    font-size: 30px;
}


.line_h {
    line-height: 10px;
}

.item_end {
    /* padding-left: 5px; */
    font-weight: 100 !important;
}

.section_1,
.section_2,
.section_3,
.section_4,
.section_5,
.section_6,
.section_7,
.section_8 {
    margin-top: -4px !important;
}

.section_2,
.section_4,
.section_7 {
    position: relative;
}

.shape_1 {
    position: absolute;
    top: 0;
    left: -250px;
    z-index: 2;
}

.shape_2 {
    position: absolute;
    top: -100px;
    right: -500px;
    z-index: 100;
}

.shape_3 {
    position: absolute;
    left: -100px;
    bottom: 0;
}

.shape_4 {
    position: absolute;
    right: -500px;
    bottom: 0;
}

.shape_5 {
    position: absolute;
    top: 100px;
    left: -130px;
}

.shape_6 {
    position: absolute;
    bottom: 0;
    right: -250px;
}

.container {
    padding: 0 50px !important;
}



/* media query */

@media (max-width : 784px) {
    .banner_logo {
        width: 90%;
    }

    .section_1 {
        height: 90vh !important;
    }

    .shape_1 {
        position: absolute;
        top: 0;
        left: 350px !important;
        display: none;

    }

}

@media (max-width : 1200px) {
    .banner_logo {
        width: 90%;
    }

    .section_1 {
        height: 110vh !important;
    }

    .section_9 {
        position: static !important;
    }

    .section_7 {
        position: relative !important;
    }
}


@media (min-width: 1400px) {
    .banner_title {
        font-size: 85px !important;
        padding-top: 40px;
    }

    .banner_content {
        margin-top: 150px;
    }

    .parallax-time {
        padding-top: 40px;
    }

    .time {
        font-size: 80px !important;
    }

    p {
        font-size: 20px !important;
    }

    .cavin_logo {
        width: 250px;
    }

    .section_5 {
        background-image: url('../img/Immersive_bg.png');
        background-position: center;
        background-size: 100% 100%;
        background-repeat: no-repeat;

    }

    .container {
        padding: 0 0 !important;
    }


}